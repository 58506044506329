import React, { useEffect, useState } from "react"
import { navigate, injectIntl } from "gatsby-plugin-intl"

import Image from "./image"
import { getAmazonPrice } from "../utils"

const ProductBox = ({ product, isRelated, intl, style }) => {
  const [amazonPrice, setAmazonPrice] = useState("")
  const specs = product.specs ? product.specs.split("\n").join("\n\n") : ""
  useEffect(() => {
    async function fetchData(asin) {
      const price = await getAmazonPrice(asin)
      setAmazonPrice(price)
    }
    if (product.asin) {
      fetchData(product.asin)
    }
  }, [product.asin])
  return (
    <div className="c-product-box" style={style}>
      {isRelated && (
        <div className="c-product-box__related">
          {intl.formatMessage({ id: "terms.related_product" })}
        </div>
      )}
      <div className="c-product-box__header">
        <div className="c-product-box__image">
          <Image image={product.image} size="medium" />
        </div>
        <div className="c-product-box__info">
          <div className="c-product-box__name">{product.name}</div>
          {product.asin && (
            <div className="c-product-box__price">{amazonPrice}</div>
          )}
        </div>
      </div>
      {!isRelated ? (
        <div className="c-product-box__content">{specs}</div>
      ) : (
        <div className="c-product-box__content">
          {product.short_description}
        </div>
      )}
      {!isRelated && (
        <div
          className="c-product-box__footer"
          onClick={() =>
            navigate(`/prodotto/${product.item_id}`)
          }
          onKeyDown={null}
        >
          {intl.formatMessage({ id: "terms.discover_product" })}
        </div>
      )}
    </div>
  )
}

export default injectIntl(ProductBox)
