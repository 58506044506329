import { graphql } from "gatsby"
import React, { useEffect, useState, useContext } from "react"
import { injectIntl } from "gatsby-plugin-intl"

import cookie from "cookie"

import { localize, createMarkup, createAmazonLink, getImageUrl } from "../utils"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"
import SpecsBox from "../components/specs-box"
import ProductBox from "../components/product-box"
import Carousel from "../components/carousel"
import Gallery from "../components/gallery"
import productFormatter, {
  getFinalPrice,
} from "../utils/magento/productFormatter"

import { Dropdown } from "../components/dropdown"

import { Context } from "../context/Context"

export const query = graphql`
  query($item_id: String!) {
    main: directusProduct(item_id: { eq: $item_id }) {
      home
      data {
        name
        specs
        short_description
        long_description
        lang
      }
      id
      item_id
      asin
      shop_link
      price
      video_link
      manual_download
      app_manual_download
      image {
        title
        private_hash
        localFile {
          childImageSharp {
            fixed(height: 260) {
              src
              srcSet
              srcSetWebp
            }
          }
        }
      }
      additional_images {
        title
        private_hash
        localFile {
          childImageSharp {
            fixed(height: 260) {
              src
              srcSet
              srcSetWebp
            }
          }
        }
      }
      gallery {
        title
        private_hash
        localFile {
          small: childImageSharp {
            fixed(width: 240) {
              src
            }
          }
          medium: childImageSharp {
            fixed(width: 960, quality: 100) {
              src
            }
          }
          large: childImageSharp {
            fixed(width: 1920) {
              src
            }
          }
        }
      }
    }
    related: directusProduct(related_to: { eq: $item_id }) {
      home
      data {
        name
        specs
        short_description
        long_description
        lang
      }
      id
      asin
      image {
        title
        private_hash
        localFile {
          childImageSharp {
            fixed(height: 260) {
              srcSet
              srcSetWebp
            }
          }
        }
      }
    }
  }
`

const ProductPage = ({
  data: { main: productData, related: relatedData },
  intl,
}) => {
  const [productPrice, setProductPrice] = useState("")
  const [productLink, setProductLink] = useState("")
  const [m2Product, setM2Product] = useState(null)
  const [formattedProd, setFormattedProd] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState({})
  const [addToCartEnabled, setAddToCartEnabled] = useState(false)

  const { updateCart, setToast } = useContext(Context)

  useEffect(() => {
    async function fetchData(sku) {
      try {
        const resM2Product = await fetch(`/api/m2/product/${sku}`)
        const m2Product = await resM2Product.json()
        setM2Product(m2Product)
        const formattedProduct = productFormatter(m2Product)
        setFormattedProd(formattedProduct)
        console.log("Prodotto: ", JSON.stringify(formattedProduct))
        setProductPrice(formattedProduct.finalPrice.formattedValue)
        // TODO: sistemare qui link
        setProductLink(`https://shop.farmamed.it/${sku}`)
      } catch (err) {
        console.log('Prodotto mancante', err)
      }
    }

    const sku = productData.item_id
    fetchData(sku)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const options = formattedProd?.swatch || []
    if (options.length === Object.keys(selectedOptions).length) setAddToCartEnabled(true)
    else setAddToCartEnabled(false)
  }, [formattedProd, selectedOptions])

  const product = localize(productData, intl.locale)
  const relatedProduct = localize(relatedData, intl.locale)

  const carouselImages =
    product.additional_images.length > 0
      ? product.additional_images.map(
        img => img.localFile.childImageSharp.fixed.src
      )
      : undefined
  if (carouselImages)
    carouselImages.unshift(product.image.localFile.childImageSharp.fixed.src)

  const galleryImages =
    product.gallery.length > 0
      ? product.gallery.map(img => {
        return {
          fullscreen: img.localFile.large.fixed.src,
          original: img.localFile.medium.fixed.src,
          thumbnail: img.localFile.small.fixed.src,
        }
      })
      : undefined

  const addToCart = async () => {
    try {
      const cookies = cookie.parse(document?.cookie);
      if (!cookies['m2-cart']) {
        console.log('No cart stored in cookies')
        await fetch('/api/m2/cart/load')
      }
      const cartRes = await fetch("/api/m2/cart/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ product: { ...m2Product, selected_options: selectedOptions } }),
      })
      const cartContent = await cartRes.json()
      updateCart(cartContent)
      // TODO: traduzioni toast
      setToast({
        type: "success",
        title: "Aggiunto al carrello",
        message: `Il prodotto è stato aggiunto al carrello`,
      })
    } catch (err) {
      console.log(err)
      setToast({
        type: "error",
        title: "Errore",
        message: `Impossibile aggiungere al carrello`,
      })
    }
  }

  const options = formattedProd?.swatch || []

  const changeOption = (code, value) => {
    setSelectedOptions({ ...selectedOptions, [code]: value })
  }
  return (
    <Layout name="p-product">
      <Seo
        title={product.name}
        description={product.short_description}
        imageUrl={getImageUrl(product.image)}
      />
      <div className="p-product__header">
        <div className="a-container">
          <div className="p-product__header-content">
            <div className="p-product__header-info">
              <h1>{product.name}</h1>
              <div className="p-product__abstract">
                {product.short_description}
              </div>
              <div className="p-product__options">
                {options.map(opt => {
                  return <Dropdown
                    key={opt.label}
                    placeholder={opt.label}
                    options={opt.values.map(value => ({ label: value.label, data: value.uid }))}
                    onChange={value => changeOption(opt.attribute_code, value.data)}
                  />
                })}
              </div>
              {product.asin && productLink && (
                <div className="p-product__header-price">
                  {productPrice && (
                    <span className="p-product__price">{productPrice}</span>
                  )}
                  <a
                    onClick={addToCartEnabled && addToCart}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={addToCartEnabled ? "a-btn a-btn--primaryAlt" : "a-btn  a-btn--disabled"}
                    style={!productPrice ? { marginLeft: 0 } : {}}
                  >
                    {intl.formatMessage({ id: "terms.buy" })}
                  </a>
                </div>
              )}
            </div>
            {!product.video_link ? (
              <div className="p-product__header-image position-relative">
                <div className="a-dots a-dots--alt middle-left a-dots--large"></div>
                {!carouselImages && <Image image={product.image} center />}
                {carouselImages && (
                  <Carousel
                    images={carouselImages}
                    slides={1}
                    breakpoints={null}
                  />
                )}
              </div>
            ) : (
              <div className="p-product__header-video position-relative">
                <div className="a-dots a-dots--alt middle-left a-dots--large"></div>
                <iframe
                  title="video"
                  width="100%"
                  height="100%"
                  src={`https://www.youtube-nocookie.com/embed/${product.video_link.split("?v=")[1]
                    }?controls=0`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="a-container">
        <div className="p-product__content">
          <div className="p-product__main">
            <div
              dangerouslySetInnerHTML={createMarkup(product.long_description)}
            />
            {galleryImages && (
              <div style={{ marginTop: "50px" }}>
                <Gallery images={galleryImages} />
              </div>
            )}
          </div>
          <div className="p-product__sidebar">
            {(product.manual_download || product.app_manual_download) && (
              <div className="p-product__download-buttons">
                {product.manual_download && (
                  <a
                    href={product.manual_download}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="a-btn"
                    style={{ marginBottom: "14px" }}
                  >
                    {intl.formatMessage({ id: "terms.download_manual" })}
                  </a>
                )}
                {product.app_manual_download && (
                  <a
                    href={product.app_manual_download}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="a-btn"
                  >
                    {intl.formatMessage({ id: "terms.download_app_manual" })}
                  </a>
                )}
              </div>
            )}
            {product.specs && <SpecsBox product={product} />}
            {Object.keys(relatedProduct).length > 0 && (
              <ProductBox product={relatedProduct} isRelated={true} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(ProductPage)
