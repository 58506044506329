import React from "react"
import Select, { components } from "react-select"
import { UilAngleDown } from "@iconscout/react-unicons"

const dropdownStyle = {
  control: styles => ({
    ...styles,
    backgroundColor: "white",
    border: 0,
    borderRadius: "7px",
  }),
  indicatorSeparator: styles => ({ display: "none" }),
  container: styles => ({
    ...styles,
    fontSize: "12px",
    fontFamily: "Montserrat",
    padding: 0,
    fontWeight: 600,
    color: "#102342",
  }),
  menu: styles => ({ ...styles, border: 0 }),
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <UilAngleDown />
    </components.DropdownIndicator>
  )
}

const SingleValue = ({ children, data, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {children}
      </div>
    </components.SingleValue>
  )
}

const Dropdown = ({ placeholder, options, onChange }) => {
  return (
    <Select
      options={options}
      styles={dropdownStyle}
      components={{ DropdownIndicator, SingleValue }}
      placeholder={placeholder}
      onChange={onChange}
      isSearchable={false}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#004b93",
          primary75: "#3b82f5",
          primary25: "#CEDCF5",
        },
      })}
    />
  )
}

export { Dropdown }
