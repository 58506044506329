const getCurrencySymbol = (price) => {
    if (price.currency === 'EUR') return '€'
    return '€'
}
const priceValueFormatter = (priceValue, locale = 'it-IT') => {
    const formattedNum = priceValue.toLocaleString(locale, {
        minimumFractionDigits: 2,
    }) // qui sarebbe da tener conto della country
    return formattedNum
}

const priceToString = (pricePayload, locale = 'it-IT') => {
    if (!pricePayload || !pricePayload.value || !pricePayload.currency) {
        pricePayload = {
            value: 0,
            currency: 'EUR'
        }
    }
    return `${priceValueFormatter(pricePayload.value, locale)} ${getCurrencySymbol(pricePayload.currency)}`
}

const getFinalPrice = (product, variant) => {
    if (!product.price_range) return {
        formattedValue: ''
    }
    let price = { ...product.price_range.minimum_price.final_price }
    if (variant) {
        price = { ...variant.price_range.minimum_price.final_price }
    }

    price.formattedValue = priceToString(price, 'it-IT')
    return price
}

const getRegularPrice = (product, variant) => {
    if (!product.price_range) return {
        formattedValue: ''
    }
    // tra tutti i prezzi, il mmassimo REGULAR (dunque SENZA di sconto)
    let price = { ...product.price_range.maximum_price.regular_price }
    if (variant) {
        price = { ...variant.price_range.maximum_price.regular_price }
    }

    price.formattedValue = priceToString(price, 'it-IT')
    return price
}

const getVariant = product => {
    return product.configurable_product_options_selection?.variant
}

const getRelatedProducts = product => {
    return product.related_products && product.related_products.length > 0 ? product.related_products : null
}

const getImage = product => {
    // if (product.cloudinary_images && product.cloudinary_images.length > 0) {
    //     return product.cloudinary_images[0].file
    // }
    // if (product.image) {
    //     return product.image.file
    // }
    const sku = product.sku.replace('P-', '')
    return { public_id: `catalogo/${sku}/${sku}` }
}

const getSwatchOptions = product => {
    const swatchOptions = []
    const cfgOptions = product.configurable_options
    if (!cfgOptions) return swatchOptions
    cfgOptions.forEach(opt => {
        swatchOptions.push({
            label: opt.label,
            attribute_code: opt.attribute_code,
            attribute_uid: opt.attribute_uid,
            values: opt.values
        })
    })
    return swatchOptions
}

const productFormatter = (product, variant) => {
    // se non ha name, c'è qualcosa di sbagliato
    if (!product.name) return null
    const name = product.name
    const finalPrice = getFinalPrice(product, variant)
    const regularPrice = getRegularPrice(product, variant)
    const swatchOptions = getSwatchOptions(product)

    return {
        sku: product.sku,
        variant: !!variant,
        images: product.cloudinary_images,
        main_image_file: getImage(product),
        swatch: swatchOptions,
        url_key: product.url_key,
        link: `/product/${product.url_key}/${product.sku}`,
        name,
        finalPrice,
        regularPrice,
        categories: product.categories,
        special: product.special || variant?.special,
        salePerc: parseInt((regularPrice.value - finalPrice.value) / regularPrice.value * 100),
        isOnSale: finalPrice.value !== regularPrice.value
    }

}

export { getFinalPrice, getRegularPrice, getCurrencySymbol, getVariant, getRelatedProducts, getSwatchOptions, priceValueFormatter, priceToString }
export default productFormatter