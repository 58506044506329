import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

const ProductBox = ({ product, intl }) => {
  const specs = product.specs ? product.specs.split("\n").join("\n\n") : ""
  return (
    <div className="c-specs-box">
      <div className="c-specs-box__header">
        {intl.formatMessage({ id: "terms.specs" })}
      </div>
      <div className="c-specs-box__content">{specs}</div>
    </div>
  )
}

export default injectIntl(ProductBox)
